<template>
  <div class="container">
    <h2>{{ title }}</h2>
    <h4>{{ subtitle }}</h4>
    <br>
    <div class="p-5 bg-white rounded shadow mb-5">
      <b-tabs pills fill>
<!--        <b-tab title="縮網址">-->
<!--          <div class="px-4 py-5">-->
<!--            <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>-->
<!--            <p class="text-muted mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>-->
<!--          </div>-->
<!--        </b-tab>-->
        <b-tab title="縮圖片" active>
          <div class="px-4 py-5">
            <UploadMedia :mediaType="'image'"/>
          </div>
        </b-tab>
        <b-tab title="縮影片">
          <div class="px-4 py-5">
           <UploadMedia :mediaType="'video'"/>
          </div>
        </b-tab>
      </b-tabs>
      <!-- End rounded tabs -->
    </div>
  </div>
</template>

<script>
import UploadMedia from "@/components/UploadMedia.vue";

export default {
  components: {UploadMedia},
  data() {
    return {
      title: '免費、快速 縮網址',
      subtitle: '圖片 影片 快速產生縮短網址'
    };
  },
};
</script>

<style scoped>
.container {
  //padding-top: 100px;
  //padding: 56px;
}

</style>
