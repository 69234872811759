<template>
  <div>
    <file-pond
        ref="pond"
        name="file"
        :label-idle="label"
        :allow-multiple="allowMultiple"
        :accepted-file-types="accepted_file_types"
        :maxFiles="maxFilesCount"
        :files="imageFiles"
        instant-upload="true"
        credits="false"
    />
    <div class="option-container">
      <div class="row">
        <div class="col-md-4">
          <div class="option-title">密碼</div>
          <div style="position: relative;">
            <input type="text" v-model="password" placeholder="請輸入密碼" class="option-description form-control">
            <div class="button-wrapper">
              <button class="btn btn-primary" @click="insertTodayDate">{{ today }}</button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="option-title">瀏覽期限</div>
          <select class="form-select option-description" v-model="expired" aria-label="選擇期限">
            <option v-for="option in expiredOptions" :value="option.value" :key="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <div class="option-title">瀏覽次數限制</div>
          <input type="number" v-model="limit" placeholder="預設無限次(選填)"
                 class="option-description form-control">
        </div>
      </div>
      <div class="row second-row">
        <div class="col">
          <div class="option-title">說明或描述</div>
          <input type="text" v-model="description" placeholder="請輸入說明或描述(選填)"
                 class="option-description form-control">
        </div>
      </div>
    </div>
    <button @click="submitForm" class="submit-button btn btn-primary"
            :class="{ 'btn-danger': error, 'disabled': isUploading }" :disabled="isUploading" id="load2">
      <span v-if="isUploading"><i class='fa fa-spinner fa-spin'></i>  上傳中</span>
      <span v-else-if="error">上傳失敗</span>
      <span v-else>立即產生 🔗</span>
    </button>

    <!-- Modal -->
    <b-modal
        id="modal-center"
        v-model="isUploadedSuccess"
        centered title="上傳成功🥳🥳"
        hide-header-close
        ok-title="立即前往，並複製到剪貼簿"
        cancel-title="關閉"
        @ok="okButtonForModal"
    >
      <p class="my-4">恭喜產生短網址成功<br>若有設定密碼請輸入正確密碼即可瀏覽短網址。</p>
      <div class="row">
        <div class="col-md-8 d-flex justify-content-start"> <!-- 假設佔8個格子 -->
          <a :href="uploadSuccessUrl" target="_blank" class="mr-2">{{ uploadSuccessUrl }}</a>
        </div>
        <div class="col-md-4 d-flex justify-content-end"> <!-- 假設佔4個格子 -->
          <div class="copy-to-clipboard-button">
            <button class="btn" @click="copyToClipboard">複製到剪貼簿</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {shrinkImage} from "shrinkpng"
import {upload} from '@/repositories/source_repository'


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
)
export default {
  props: ['mediaType'],
  components: {
    FilePond
  },
  data() {
    return {
      error: null,
      isUploading: false,
      isUploadedSuccess: false,
      uploadSuccessUrl: null,
      allowMultiple: true,
      maxFilesCount: 5,
      imageFiles: [],
      password: '',
      limit: null,
      description: null,
      today: '',
      accepted_file_types: null,
      label: '',
      expiredOptions: [
        {value: 0, label: "無限制"},
        {value: 1, label: "1分鐘"},
        {value: 5, label: "5分鐘"},
        {value: 15, label: "15分鐘"},
        {value: 30, label: "30分鐘"},
        {value: 60, label: "1小時"},
        {value: 120, label: "2小時"},
        {value: 240, label: "4小時"},
        {value: 480, label: "8小時"},
        {value: 1440, label: "1天"},
        {value: 2880, label: "2天"},
        {value: 4320, label: "3天"},
        {value: 10080, label: "7天"}
      ],
      expired: 0 // 預設值
    };
  },
  mounted() {
    this.setAcceptedFileTypes();
    this.getTodayDate();
  },
  methods: {
    async compressImage(file) {
      // 检查文件类型
      if (!file.type.startsWith('image/')) {
        // 不是图片，直接返回原文件
        return file;
      }

      // 根据文件大小选择压缩质量
      const fileSize = file.size;
      const quality = this.getQuality(fileSize);
      if (quality === 100) {
        return file;
      }
      try {
        return await shrinkImage(file, {
          quality: quality, // 压缩质量
          maxWidth: 800, // 最大宽度
          maxHeight: 600 // 最大高度
        });
      } catch (error) {
        // 压缩失败，返回原文件
        console.error('Error compressing image:', error);
        return file;
      }
    },
    getQuality(fileSize) {
      if (fileSize >= 10 * 1024 * 1024) {
        return 50;
      } else if (fileSize >= 8 * 1024 * 1024) {
        return 60;
      } else if (fileSize >= 5 * 1024 * 1024) {
        return 70;
      } else if (fileSize >= 4 * 1024 * 1024) {
        return 80;
      } else if (fileSize >= 2 * 1024 * 1024) {
        return 90;
      } else {
        return 100;
      }
    },
    calculateExpiredTime(offset = 0) {
      const offsetMs = offset * 60 * 1000
      const date = new Date(Date.now() + offsetMs)
      return date.toJSON()
    },
    setAcceptedFileTypes() {
      if (this.mediaType === 'image') {
        this.accepted_file_types = 'image/*';
        this.label = `將您要上傳的圖片、照片拖入框中，或點擊選擇，最多${this.maxFilesCount}張照片`;
      } else if (this.mediaType === 'video') {
        this.accepted_file_types = 'video/*';
        this.maxFilesCount = 1;
        this.allowMultiple = false;
        this.label = `將您要上傳的影片拖入框中，或點擊選擇，每次只能上傳${this.maxFilesCount}部影片`;
      } else {
        console.error('Invalid mediaType:', this.mediaType);
      }
    },
    getTodayDate() {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      this.today = `${month < 10 ? '0' : ''}${month}${day < 10 ? '0' : ''}${day}`;
    },
    insertTodayDate() {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      this.password = `${month < 10 ? '0' : ''}${month}${day < 10 ? '0' : ''}${day}`;
    },
    async submitForm() {
      this.isUploading = true;
      const files = this.$refs.pond.getFiles();
      if (files.length === 0) {
        this.isUploading = false;
        return;
      }
      const compressedFiles = [];
      for (const file of files) {
        try {
          const compressedFile = await this.compressImage(file.file);
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      }
      try {
        const compressedImages = await Promise.all(compressedFiles);
        const formData = new FormData();
        formData.append('data', JSON.stringify({
          password: this.password,
          expired_at: (this.expired !== 0) ? this.calculateExpiredTime(this.expired) : null,
          limit: parseInt(this.limit) || -1,
          donate: false,
          type: this.mediaType,
          description: this.description,
        }));

        // 添加壓縮後的圖片檔案到 FormData
        compressedImages.forEach((file, index) => {
          console.log(index);
          formData.append(`file`, file);
        });

        // 使用 POST 方法以 multipart/form-data 格式上傳
        const res = await upload(formData);
        this.uploadSuccessUrl = res.short_url;
        this.isUploadedSuccess = true;
        // this.uploadSuccessUrl = window.location.origin + 'nanolink' + '/'
        // this.uploadSuccessUrl += (res !== null && res.short_url !== undefined) ? res.short_url : ''
      } catch (error) {
        this.error = error;
      } finally {
        this.isUploading = false; // 無論成功或失敗都要設置為 false
      }
    },
    okButtonForModal() {
      const url = `https://nanolink.cc/${this.uploadSuccessUrl}`; 
      navigator.clipboard.writeText(url)
          .then(() => {
            window.location.href = url;
          })
          .catch(err => {
            console.error('無法複製網址到剪貼簿：', err);
          });
    },
    copyToClipboard() {
      const url = `https://nanolink.cc/${this.uploadSuccessUrl}`;
      navigator.clipboard.writeText(url)
          .then(() => {
            console.log('網址已複製到剪貼簿！');
          })
          .catch(err => {
            console.error('無法複製網址到剪貼簿：', err);
          });
    }
  },
}
</script>

<style scoped>

.option-container {
  margin-top: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.option-title {
  text-align: left;
  margin-bottom: 6px;
}

.option-description {
  width: 100%;
  margin-bottom: 20px;
}

.submit-button {
  width: 100%;
  margin-top: 20px;
}

.button-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 10px;
}

.button-wrapper button {
  border-radius: 25px;
  border: none;
  background: #dc3545;
  transform: scale(0.6); /* 缩小按钮大小为原来的一半 */
}

.copy-to-clipboard-button .btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0.5em 1em;
  cursor: pointer;
  width: fit-content; /* 寬度與字體長度相同 */
  font-size: 12px;
}

.copy-to-clipboard-button .btn:hover {
  background-color: darkred;
}
</style>
