<template>
  <div id="app">
    <NavbarComponent/>
    <div class="page-content">
      <router-view/>
    </div>
    <FooterComponent :footerPosition="footerPosition" class="footer"/>
  </div>
</template>

<script>
import NavbarComponent from './components/Navbar.vue'
import FooterComponent from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    NavbarComponent,
    FooterComponent,
  },
  data() {
    return {
      footerPosition: 'absolute' // 初始设置为绝对定位
    };
  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#app {
  text-align: center;
}
//
.page-content {
  padding-top: 80px;
  min-height: 100vh; //body設為100%的視窗高
}

.footer {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
}
</style>