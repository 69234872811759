import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 導入 Bootstrap 和 BootstrapVue
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/css/global.css'
// 导入element-plus
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Ads from 'vue-google-adsense'

Vue.config.productionTip = false

// 安裝 BootstrapVue 插件
Vue.use(BootstrapVue)
// Vue.use(ElementUI)

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

const firebaseConfig = {
  apiKey: "AIzaSyD5O7Byx2aPXntvr0E5OWOqqbXdE2mvvqA",
  authDomain: "nanolink-application.firebaseapp.com",
  projectId: "nanolink-application",
  storageBucket: "nanolink-application.appspot.com",
  messagingSenderId: "21705941022",
  appId: "1:21705941022:web:525f032263d555ee8b0e0f",
  measurementId: "G-D8CN118N2Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
