<template>
  <div class="navbar">
    <nav class="navbar custom-nav navbar-expand-lg w-100">
      <router-link to="/" class="navbar-brand">
        <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" width="70%">
      </router-link>
<!--      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">-->
<!--        <div class="navbar-nav">-->
<!--          <a class="nav-link active" aria-current="page" href="#">縮照片</a>-->
<!--          <a class="nav-link" href="#">縮影片</a>-->
<!--        </div>-->
<!--      </div>-->
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavbarComponent',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.navbar {
  width: 100vw;
  margin: 0;
  padding: 0;

  .custom-nav {
    background-color: #000000;

    .navbar-brand {
      .logo {
        height: 30px;
      }
      padding-top: 16px;
      padding-left: 24px;
      padding-bottom: 16px;
    }

    .nav-link {
      margin-top: 8px;
      color: $white;
    }
  }
}

/* 重置 Bootstrap 中的默认边距和内边距 */
.navbar-expand-lg {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav {
  margin: 0;
}

.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
