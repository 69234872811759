import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: "nanolink - 快速產生短網址"
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicyView,
        meta: {
            title: "nanolink - 隱私權政策"
        }
    },
    // {
    //     path: '/terms',
    //     name: 'terms',
    //     component: TermsView,
    //     meta: {
    //         title: "nanolink - 使用條款"
    //     }
    // },
    {
        path: '/:id',
        component: () => import('../views/InputPasswordView.vue'),
        meta: {
            title: "nanolink - 快速產生短網址"
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 路由切換前設置標題
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'nanolink - 免費快速 縮網址'; // 如果沒有設置標題，則使用預設標題
    next();
});

export default router
