<template>
  <footer class="footer text-light">
    <div class="container">
      <div class="row">
        <div class="col-sm-2">
          <div class="footer-category">
            <h5 class="footer-heading">我們的服務</h5>
            <ul class="list-unstyled">
              <li><a href="/" class="text-light">縮圖片</a></li>
              <li><a href="/" class="text-light">縮影片</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="footer-category">
            <h5 class="footer-heading">關於</h5>
            <ul class="list-unstyled">
              <li><a href="/privacy" class="text-light">隱私權政策</a></li>
<!--              <li><a href="/terms" class="text-light">使用條款</a></li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <img alt="pastedImage" :src="require('@/assets/logo.svg')" class="footer-branding grayscale">
      </div>
      <span class="copyright">Copyright © nanolink - 2025</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
.footer {
  background-color: #000000;
}

.text-light {
  text-decoration: none;
}

.footer-category {
  //padding-bottom: 20px;
}

.footer-heading {
  //margin-bottom: 15px;
}

.footer-branding {
  max-width: 100px;
}

.grayscale {
  filter: grayscale(100%);
}
.copyright {
  text-align: center; /* 将文字水平居中 */
  color: #9D9D9D;
}
</style>
