import axios from 'axios';

// 定義一個方法來發送 POST 請求
export function unlock(data) {
  return axios.post('unlock', data);
}

export const upload = async (data) => {
  return await axios
    .post('api/v0/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => {
      console.log('postUpload:', res)
      if (res.status === 200) {
        console.log('上傳成功', res.data)
        return res.data
      }
      return false
    })
    .catch((error) => {
      console.log(`呼叫 upload API失敗[${error}]`)
      return false
    })
}